import { ActivityInputValues } from 'components/shared/activityInput/useActivityInput';
import AttrValue from 'models/AttrValue';
import Classification from 'models/Classification';
import Group from 'models/Group';
import { RehabStateName } from 'models/RehabState';
import User from 'models/User';

export enum PatientFormMode {
  Edit,
  New,
  Connecting,
}

type SelectValue = { label: string; value: string };
export const emptySelectValue = { label: '', value: '' };

export type FormValues = {
  externalId: string;

  // Patient information
  name: string;
  dateOfBirth: Date | null;
  sex: SelectValue;

  // Episode information
  utilizationManager: User;
  caseManager: User;
  hospital: Group;
  planTypeClassification: Classification;
  episodeClassifications: Classification[];
  physicianTeam: AttrValue;
  admittedOn: string;
  antHospitalDischarge: string;
  owner: Group;
  payer: Group;
  physicianGroup: Group;
  transferredFrom: Group;

  // Provider information
  locationType: SelectValue;
  rehabFacility: Group;

  note?: ActivityInputValues;
};

export type FormMeta = {
  mode: PatientFormMode;
  isSubmitting: boolean;
  isUploadingAttachments: boolean;
  patientHasActiveServiceRefusals: boolean;
  currentRehabState: RehabStateName;
  latestLocationEpisodeIsArchived: boolean;
  isManagerUser: boolean;
};
