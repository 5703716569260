import { useRef, useState } from 'react';
import styled from 'styled-components';

import { useMutation, useQueryClient } from '@tanstack/react-query';

import useActivityInput from 'components/shared/activityInput/useActivityInput';
import Alert from 'components/shared/Alert';
import Button from 'components/shared/Button';
import DatePicker from 'components/shared/form/DatePicker';
import InputGroup from 'components/shared/form/InputGroup';
import StandardModal, { Actions, PatientName } from 'components/shared/StandardModal';
import { simpleDate } from 'lib/date';
import { ActivityType } from 'models/Activity';
import LocationEpisode from 'models/LocationEpisode';
import { QuestionType } from 'models/QuestionTemplate';
import { RehabStateName } from 'models/RehabState';
import { activityQueryKeys, createActivity } from 'services/api/activity';
import { attachmentsQueryKeys } from 'services/api/attachments';
import { episodeQueryKeys } from 'services/api/episode';
import { progressChartQueryKeys } from 'services/api/locationEpisode/progressChart';
import { locationEpisodeQueryKeys } from 'services/api/locationEpisodes';

import QuestionList, { QuestionListRefType } from './QuestionList/QuestionList';

export type DischargeModalType = {
  setShow: (b: boolean) => void;
  patientName: string;
  locationEpisode: LocationEpisode;
  title: string;
  invalidateData?: () => void;
};

export default function PatientStatusUpdateModal(props: DischargeModalType) {
  const { setShow, patientName, locationEpisode, title, invalidateData } = props;
  const questionsRef = useRef<QuestionListRefType>(null);
  const [admittedDate, setAdmittedDate] = useState<Date | null>(null);
  const [allQuestionsAnswered, setAllQuestionsAnswered] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const { note, setNote, resetNote, getSerializedNoteValues } = useActivityInput();
  const queryClient = useQueryClient();

  const { mutate, isPending } = useMutation({
    mutationFn: createActivity,
  });

  const cancel = () => {
    questionsRef.current?.reset();
    resetNote();
    setAllQuestionsAnswered(false);
    setAdmittedDate(null);
    setShow(false);
  };

  const submit = () => {
    mutate(
      {
        ...getSerializedNoteValues(),
        type: ActivityType.PROGRESS_UPDATE,
        questions: questionsRef.current!.getAnswers(),
        admittedAt: admittedDate ?? undefined,
        locationEpisodeId: locationEpisode.id,
      },
      {
        onSuccess: () => {
          // only invalidate episode and location episode if the progress update results in the start of treatment
          if (locationEpisode.currentRehabState.state !== RehabStateName.InTreatment) {
            queryClient.invalidateQueries({ queryKey: episodeQueryKeys.show({ id: locationEpisode.episodeId }) });
          }
          queryClient.invalidateQueries({ queryKey: locationEpisodeQueryKeys.show({ id: locationEpisode.id }) });
          queryClient.invalidateQueries({ queryKey: activityQueryKeys.base });
          queryClient.invalidateQueries({ queryKey: attachmentsQueryKeys.count() });
          queryClient.invalidateQueries({ queryKey: progressChartQueryKeys.show(locationEpisode.id) });
          invalidateData?.();
          cancel();
        },
      }
    );
  };

  const latestStatus = locationEpisode.latestProgressUpdate?.data?.questions?.find((q) => q.kind === 'status')?.answer
    .text;

  const submitDisabled =
    isUploading || !allQuestionsAnswered || (locationEpisode?.currentRehabState.state === 'Queue' && !admittedDate);

  const questions: QuestionType[] = locationEpisode.progressTemplate.questions;

  return (
    <StandardModal title={title} onCancel={cancel}>
      <PatientName>Patient: {patientName}</PatientName>
      <FormContainer>
        {locationEpisode?.dischargeOverdue && (
          <Alert style={{ marginBottom: 16 }}>
            <Alert.Text>
              Patient&apos;s projected discharge date of&nbsp;
              <b>{simpleDate(locationEpisode.projectedDischargeReview?.date)}</b>
              &nbsp; has passed. Please provide details regarding any barriers to discharge.
            </Alert.Text>
          </Alert>
        )}
        {locationEpisode?.currentRehabState.state === 'Queue' && (
          <InputGroup title='Actual Admission Date'>
            <DatePicker
              data-cy='actualAdmissionDate'
              placeholder='Select Date'
              maxDate={new Date()}
              selected={admittedDate}
              onChange={setAdmittedDate}
            />
          </InputGroup>
        )}
        <QuestionList
          ref={questionsRef}
          questions={questions}
          setAllQuestionsAnswered={setAllQuestionsAnswered}
          latestStatus={latestStatus}
          locationEpisode={locationEpisode}
          noteValues={note}
          setNoteValues={setNote}
          resetNoteValues={resetNote}
          setIsUploading={setIsUploading}
        />
      </FormContainer>
      <Actions>
        <Button variant='ghost' onClick={cancel}>
          Cancel
        </Button>
        <Button onClick={submit} disabled={submitDisabled} loading={isPending}>
          Submit
        </Button>
      </Actions>
    </StandardModal>
  );
}

const FormContainer = styled.div`
  padding-bottom: 20px;
`;
