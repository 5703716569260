import React from 'react';
import styled from 'styled-components';

import { RehabStateName } from 'models/RehabState';
import { Label } from 'styles/typography';
import AdmissionEmptyState from 'svg/AdmissionEmptyState';
import DischargedEmptyState from 'svg/DischargedEmptyState';
import InTreatmentEmptyState from 'svg/InTreatmentEmptyState';
import QueueEmptyState from 'svg/QueueEmptyState';

const LANE_ATTRS = {
  [RehabStateName.Queue]: {
    component: QueueEmptyState,
    display: 'Queue',
  },
  [RehabStateName.Admission]: {
    component: AdmissionEmptyState,
    display: 'Admission',
  },
  [RehabStateName.InTreatment]: {
    component: InTreatmentEmptyState,
    display: 'Treatment',
  },
  [RehabStateName.Discharged]: {
    component: DischargedEmptyState,
    display: 'Discharged',
  },
};

interface LaneEmptyStateProps {
  rehabStateName: string;
}

function LaneEmptyState({ rehabStateName }: LaneEmptyStateProps) {
  const attrs = LANE_ATTRS[rehabStateName];
  const Component = attrs.component;

  return (
    <Container>
      {Component && <Component />}
      <StateLabel>No patients in {attrs.display}</StateLabel>
    </Container>
  );
}

export default LaneEmptyState;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

const StateLabel = styled(Label)`
  color: ${({ theme }) => theme.colors.black50};
  margin-top: 14px;
`;
